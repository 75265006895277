import "./src/css/style.scss";

// export const onRouteUpdate = ({ location }) => scrollToAnchor(location);

export const onRouteUpdate = ({ location }) => {
  var loader = setInterval(function () {
    if (document.readyState !== "complete") return;
    clearInterval(loader);
    // document.write("Document loaded successful!");
    scrollToAnchor(location);
  }, 300);

  return loader;
};
/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */
function scrollToAnchor(location, mainNavHeight = 0) {
  // Check for location so build does not fail
  if (location && location.hash) {
    // setTimeout(() => {
    var element = document.querySelector(`${location.hash}`);
    if (element) {
      const item = document.querySelector(`${location.hash}`).offsetTop;
      mainNavHeight =
        document.querySelector("header").offsetHeight > 0
          ? document.querySelector("header").offsetHeight
          : 0;

      window.scrollTo({
        top: item - mainNavHeight - 20,
        behavior: "smooth",
      });
    }
    // }, 1000);
  }

  return true;
}
